import { useSearchParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { auth, firestore } from "../../configs/firebase.config";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import toast, { Toaster } from "react-hot-toast";


function VerifyPayment() {
  const location = useLocation();

 const { status, txncd, order_id, phone } = location.state || {};

  const [userTxncd, setUserTxncd] = useState("");
  const [updatedTxnCd, setUpdatedTxnCd] = useState(txncd);
  const [getStatus, setGetStatus] = useState(status);
  const [showLoader, setShowLoader] = useState(false);

  const updateFirebase = async () => {
   
      //update status on firebase
      //get doc id
      console.log("Updating firebase OID is " + order_id);
      const q = query(
        collection(firestore, "biztunes"),
        where("oid", "==", order_id)
      );

      const querySnapshot = await getDocs(q);
      var docId = "";
      querySnapshot.forEach((doc) => {
        docId = doc.id;
        console.log("Found " + docId);
      });

      console.log("Doc ID is " + docId);

      if (docId !== "") {
        const docRef = doc(firestore, "biztunes", docId);
        
        await updateDoc(docRef, {
          phone: phone,
          txncd: txncd,
          status: status === "success" ? "Processing" : "Payment Failed!", 
        });

        if(status === "success"){
          //"Send Confirmation Email & SMS to user & admin");
          console.log("Sending confirmation email & SMS");

          const emailMessage = "Your custom Skiza order has been confirmed. Order ID: " + order_id + " Transaction code: " + txncd + " You Order has been successfully placed , We will notify you when your Skiza Tune is ready within the next 72 hours.";
          //post api call
          const emailData = {
            email_to: auth.currentUser.email+",admin@myskiza.com",
            email_from: 'Custom Skiza <admin@myskiza.com>',
            message: emailMessage,
            subject: 'Custom Skiza Order Confirmation',
          };

            fetch('https://api.nodemtech.com/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
            mode: 'no-cors', 
            }).then((response) => {
            console.log(response);
            });

          //send SMS
          const smsMessage = "Custom Skiza Order Confirmed. Order ID: " + order_id + " Transaction code: " + txncd + ".We will notify you when your Skiza Tune is ready within the next 72 hrs.";
         
          //format phone remove first character 0 and add 254
          phone = "254" + phone.substring(1);

          fetch(`https://platform.clickatell.com/messages/http/send?apiKey=1NMmvrV3RqmZvw4uXrO-5Q==&to=${phone}&content=${smsMessage}"`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            mode: 'no-cors', 
          }).then((response) => {
            console.log(response);
          });

        }
      }
    
  };
  const setTxncd = (e) => {
    setUserTxncd(e.target.value);
  }

  const handleVerifyTransactionCode = async () => {
    setShowLoader(true);
    const response = await fetch(`https://biztunes.nodemtech.com/verify-transaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({txncd: userTxncd, order_id: order_id}),
    });
    const data = await response.json();
    setShowLoader(false);

    if (data === "success") {
      setGetStatus("success");
      setUpdatedTxnCd(userTxncd);
    }else{
      toast.error("Could not verify M-PESA Transaction Code!");
    }
  }
  useEffect(() => {
    updateFirebase();
  }, []);

  return (
    <div className="text-center mt-20vh" style={{ margin: 20 }}>
      <Toaster />
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="card" style={{ backgroundColor: "white", padding: 10 }}>
          {getStatus === "success" ? (
            <>
              <h1>Your order has been confirmed.</h1>
              <p>
                <strong>Order ID:</strong> {order_id}
              </p>
              <p>
                <strong>Transaction code:</strong> {updatedTxnCd}
              </p>
              <p>
                You Order has been successfully placed , you will be notified
                once recording is ready.
              </p>
              <Link to="/dashboard">
                <button  className="btn btn-sm bg-theme text-white">
                    View all orders
                </button>
              </Link>
            </>
          ) : (
            <>
              <h1><i style={{color:'orange'}} className="fa fa-warning" /> Payment Failed!</h1>
              <p>
                <strong>Order ID:</strong> {order_id}
              </p>
              <p>
                Please enter the Mpesa transaction code to verify payment
              </p>
              <label>
                <input
                  type="text"
                  placeholder="Enter Mpesa Transaction Code"
                  className="form-control p-2"
                  onChange={setTxncd}
                />
                
              </label>
              <button className="btn btn-sm bg-theme text-white"
              style={{margin: '10px'}}
              onClick={handleVerifyTransactionCode}>
                {showLoader ? "Verifying..." :"Verify"}
                </button>
                {showLoader && (<LoaderIcon
              style={{
                height: "30px",
                width: "30px",
                marginBottom: "10px",
                marginLeft: "45%",
              }}
            />)}
              <p>
                We could not process your payment. Please try again in 5 minutes
              </p>
              <Link to="/dashboard">
                <button  className="btn btn-sm bg-theme text-white">
                    View all orders
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyPayment;
