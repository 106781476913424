import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { BiLogInCircle } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Contexts/AuthProvider";
import "../../styles/login.css";
import signinWithGoogle from "../../assets/images/web_light_rd_ctn@3x.png";
import logo from "../../assets/images/logo.png";
import app, { auth } from "../../configs/firebase.config";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, doc, setDoc, Timestamp } from "firebase/firestore";
import CryptoJS from "crypto-js";
import { da } from "date-fns/locale";

const Login = () => {
  const { user, loading, setLoading, signInUser, signInGoogleUser } =
    useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const data = location.state?.data || "";

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const email = userInfo.email;
    const password = userInfo.password;

    handleSignInUser(email, password);

    event.target.reset();
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError({ ...error, email: "Please Provide Your Valid Email" });
      setUserInfo({ ...userInfo, email: "" });
    } else {
      setError({ ...error, email: "" });
      setUserInfo({ ...userInfo, email: event.target.value });
    }
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    if (!/(?=.*[A-Z])/.test(password)) {
      setError({
        ...error,
        password: "Ensure the password has one uppercase letters",
      });
      setUserInfo({ ...userInfo, password: "" });
    } else if (!/(?=.*[!@#$&*])/.test(password)) {
      setError({
        ...error,
        password: "Ensure the password has one special character",
      });
      setUserInfo({ ...userInfo, password: "" });
    } else if (!/(?=.*[0-9].*[0-9])/.test(password)) {
      setError({
        ...error,
        password: "One numeric value must be used in the password",
      });
      setUserInfo({ ...userInfo, password: "" });
    } else if (!/.{8}/.test(password)) {
      setError({
        ...error,
        password: "Ensure password should be at least 8 charecters",
      });
      setUserInfo({ ...userInfo, password: "" });
    } else {
      setError({ ...error, password: "" });
      setUserInfo({ ...userInfo, password: event.target.value });
    }
  };

  const handleSignInUser = (email, password) => {
    signInUser(email, password)
      .then((result) => {
        const user = result.user;
        if (user.email === "admin@myskiza.com")
          navigate("/admin", { replace: true });
        else if (from === "/wizard" || data !== "") {
          //complete payment

          console.log("From login with data=" + data);
          handlePayment();
          //go direct to payment page
        } else navigate(from, { replace: true });

        toast.success("Welcome " + user.name ? user.name : user.email);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoogleLogin = () => {
    signInGoogleUser()
      .then((result) => {
        const user = result.user;
        toast.success("Google Login Successfull");
        if (data !== "") handlePayment();
        else navigate(from, { replace: true });
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [ipayData, setIpayData] = useState({});
  const [ipayHash, setIpayHash] = useState("");
  //const [bizInfo, setBizInfo] = useState({});

  const [ipayDialog, showIPayDialog] = useState(false); // State variable for modal visibility
  const [paymentDialog, showPaymentDialog] = useState(false); // State variable for modal visibility
  const firebaseAuth = getAuth(app);
  const firestore = getFirestore(app, "biztunes");

  const saveToFirebase = async (orderId) => {
    //collection id : biztunes
    //document id : user id
    //document data : fieldsToSave
    const fieldsToSave = {
      //get firebase auth user name
      oid: "" + orderId,
      uid: firebaseAuth.currentUser.uid,
      date: Timestamp.now(),
      name: firebaseAuth.currentUser.displayName,
      script: data.script,
      file_url: "",
      voice: data.voice,
      status: "Pending Payment",
    };

    //save new document to firebase
    await setDoc(doc(collection(firestore, "biztunes")), fieldsToSave);
  };

  //timestamp in millis as order id
  const orderId = new Date().getTime();

  const handlePayment = () => {
    const fields = {
      live: "1",
      oid: orderId.toString(),
      inv: orderId.toString(),
      ttl: "1",
      tel: data.phone,
      eml: firebaseAuth.currentUser.email,
      vid: "nodemtech",
      curr: "KES",
      p1: orderId.toString(),
      p2: data.phone,
      p3: "",
      p4: "",
      cbk: "https://myskiza.com/verifyPayment",
      cst: "1",
      crl: "2",
    };

    const datastring =
      fields.live +
      fields.oid +
      fields.inv +
      fields.ttl +
      fields.tel +
      fields.eml +
      fields.vid +
      fields.curr +
      fields.p1 +
      fields.p2 +
      fields.p3 +
      fields.p4 +
      fields.cbk +
      fields.cst +
      fields.crl;

    const hashkey = "3H48Q%uW7S#aUwBfTCjZB7T3vU@JRvWc"; // Use "demoCHANGED" for testing where vid is set to "demo"

    // Generating the HashString sample
    const generated_hash = CryptoJS.HmacSHA1(datastring, hashkey).toString();

    setIpayData(fields);
    setIpayHash(generated_hash);
    saveToFirebase(orderId);
  };

  useEffect(() => {
    const btnSubmit = document.getElementById("btnSubmit"); // Replace with your button's ID
    if (ipayHash !== "") btnSubmit.click();

    console.log("On use effect data=" ,data);
    if (location.state?.autocomplete) {
      handlePayment();
    }
  }, [ipayHash]);

  return (
    <div className="login-container">
      {/* <form action="https://payments.ipayafrica.com/v3/ke">
        {Object.keys(ipayData).map((key) => {
          return <input name={key} type="hidden" value={ipayData[key]} />;
        })}
        <input name="hsh" type="hidden" value={ipayHash} />
        <button
          id="btnSubmit"
          type="submit"
          style={{ display: "none" }}
        ></button>
      </form> */}
      <div className="login-title">
        <img src={logo} style={{ height: "64px", width: "64px" }} alt="logo" />
        <h2>MySkiza.com</h2>
      </div>
      <div className="login-title">
        <p
          style={{
            textAlign: "center",
            width: "100%",
            color: "grey",
            fontSize: "14px",
            padding: "10px",
          }}
        >
          Please Login to your account
        </p>
      </div>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="email"
          placeholder="Your Email"
          onChange={handleEmailChange}
        />
        {error?.email && (
          <div className="flex justify-center">
            <small className="error-message">{error.email}</small>
          </div>
        )}
        <input
          type="password"
          placeholder="password"
          onChange={handlePasswordChange}
        />
        {error?.password && (
          <div className="flex justify-center">
            <small className="error-message">{error.password}</small>
          </div>
        )}
        <button class="btn btn-lg bg-theme">Login</button>
        <br />
        <p>
          Don't have an account?{" "}
          <a onClick={() => navigate("/signup", { state: { data: data } })}>
            Sign up
          </a>
        </p>
      </form>

      <img
        style={{ padding: "35px", width: "100%" }}
        src={signinWithGoogle}
        onClick={handleGoogleLogin}
      />
    </div>
  );
};

export default Login;
